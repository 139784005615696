<template>
  <div class="personal-page">
    <van-popup v-model="control.postShow" position="bottom">
      <multiple-select
          ref="post"
          :checked-value="postCheckValue"
          :dataList="postList"
         @close="control.postShow = false"
          @change="postConfirm"/>
    </van-popup>
<!--    <van-popup v-model="control.post" position="bottom">-->
<!--      <van-picker-->
<!--        title="请选择职务"-->
<!--        show-toolbar-->
<!--        :columns="postList"-->
<!--        value-key="label"-->
<!--        @confirm="postConfirm"-->
<!--        @cancel="control.post = false"-->
<!--        />-->
<!--    </van-popup>-->
    <van-popup v-model="control.businesss" position="bottom">
      <multiple-select
          ref="businesss"
          :checked-value="businessCheckValue"
          :dataList="businessList"
          @close="control.businesss = false"
          @change="businessConfirm"/>
    </van-popup>
    <van-popup v-model="control.managerType" position="bottom">
      <multiple-select
          ref="managerType"
          :checked-value="managerTypeCheckValue"
          :dataList="managerTypeList"
          @close="control.managerType = false"
          @change="managerTypeConfirm"/>
<!--      <van-picker-->
<!--        title="请选择人员类型"-->
<!--        show-toolbar-->
<!--        :columns="managerTypeList"-->
<!--        value-key="label"-->
<!--        @confirm="managerTypeConfirm"-->
<!--        @cancel="control.managerType = false"-->
<!--        />-->
    </van-popup>
    <van-popup v-model="control.managementGrid" position="bottom">
      <grid-select
          ref="grid"
          :visible="control.managementGrid"
          :orgId="orgId"
          :multiple="true"
          :title="'网格/微网格'"
          @change="gridChange"
      ></grid-select>
    </van-popup>
    <div class="info">
      <div class="personal-info">
        <div class="title" :class="{'isOld40': $isOld}">个人信息</div>
        <van-cell-group :class="{'oldSize': $isOld}">
          <van-uploader :disabled="readonly" :after-read="headImgButton">
            <van-cell-group>
              <van-cell title="照片" :border="false">
                <template>
                  <img class="headImg" :src="show.headImg" alt="">
                </template>
              </van-cell>
            </van-cell-group>
          </van-uploader>
          <van-field label="姓名" v-model="dataForm.name" :readonly="readonly"/>

          <van-field label="性别" v-model="show.sex"  :readonly="true"/>

          <van-field label="手机号" v-model="dataForm.mobile" :readonly="readonly" required type="tel" />

          <van-field label="工作手机号" v-model="dataForm.workMobile" :readonly="readonly" type="tel" />

        </van-cell-group>
      </div>
      <div class="work-info">
        <div class="title" :class="{'isOld40': $isOld}">职务信息</div>
        <van-cell-group :class="{'oldSize': $isOld}">

          <van-field label="职务" v-model="show.post" :readonly="true"  type="textarea" autosize rows="1"
           @click="readonly ? '' : control.postShow = true" is-link/>

          <van-field label="负责业务" v-model="show.businesss" :readonly="true" type="textarea" autosize rows="1"
            @click="readonly ? '' : control.businesss = true" is-link />

          <van-field label="人员类型" v-model="show.managerType" type="textarea" :readonly="true" autosize rows="1"
            @click="readonly ? '' : control.managerType = true" is-link />

          <van-field label="管理网格" v-model="show.grid" :readonly="true" type="textarea" autosize rows="1"
                     @click="readonly ? '' : control.managementGrid = true" is-link >
          </van-field>

        </van-cell-group>
      </div>
    </div>
    <div class="buttons">
      <van-button v-if="readonly" class="button" @click="readonly = false">编辑</van-button>
      <van-button v-else class="button" :loading="control.buttonLoading" @click="dataFormOnSubmit()">完成</van-button>
    </div>
  </div>
</template>

<script>
import {getDictTree,getMiniGrid,upload} from '@/utils/common'
import {getImageStream} from '@/utils/index'
import gridSelect from '../../components/gridSelection/index'
import multipleSelect from '../../components/select/multipleNewSelect'
export default {
  components: {
    gridSelect,
    multipleSelect
  },
  data () {
    return {
      readonly: true,
      orgId: '',
      dataForm: {
        userId: '',
        headImg: '',
        name: '',
        mobile: '',
        workMobile: '',
        post: [],
        businesss: [],
        managerType: [],
        miniGrid: [],
        grid: [],
      },
      show: {
        sex: '',
        headImg: '',
        post: '',
        businesss: '',
        managerType: '',
        grid: '',
      },
      control: {
        postShow: false,
        businesss: false,
        managementGrid: false,
        managerType: false,
        buttonLoading: false,
      },
      managerTypeList: [],
      managerTypeCheckValue: [],
      businessList: [],
      businessCheckValue: [],
      postList: [],
      postCheckValue: [],
      gridList: [],
      hasChildrenList: [],
      noChildrenList: []
    }
  },
  mounted () {
    var _this = this
    this.orgId = this.$orgId
    // 职务列表
    getDictTree({ code: "post" }, function (list) {
      _this.postList = list
    });
    // 负责业务
    getDictTree({ code: "lineBusiness" }, function (list) {
      _this.businessList = list
    });
    // 人员类型列表
    getDictTree({ code: "managerType" }, function (list) {
      _this.managerTypeList = list
    });
    // 管理网格列表
    getMiniGrid({community:this.$orgId}, function (list) {
      _this.gridList = list
    })
    this.getUserInfo()
  },
  methods: {
    getImageStream(value) {
      return getImageStream(value)
    },
    getUserInfo() {
      this.dataForm.post = []
      this.dataForm.managerType = []
      this.dataForm.businesss = []
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/info'),
        method: 'GET',
        params: this.$http.adornParams({
          id: parseInt(this.$globalData.userInfo.userId),
          orgId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.dataForm.name = data.userManager.name
          this.dataForm.mobile = data.userManager.mobile
          this.dataForm.workMobile = data.userManager.manageMobile
          this.show.sex = data.userManager.sex == 1 ? '男' : '女'
          this.show.post = data.userManager.postName
          this.dataForm.post = data.userManager.post ? data.userManager.post.split(',').map((n) => Number(n)) : ''
          if (data.userManager.post) {
            this.postList.map(item => {
              this.dataForm.post.map(val => {
                if (item.value == val) {
                  this.postCheckValue.push(item)
                }
              })
            })
          }
          this.show.businesss = data.userManager.chargesName
          this.dataForm.businesss = data.userManager.charges ? data.userManager.charges.split(',').map((n) => Number(n)) : ''
          if (data.userManager.charges) {
            this.businessList.map(item => {
              this.dataForm.businesss.map(val => {
                if (item.value == val) {
                  this.businessCheckValue.push(item)
                }
              })
            })
          }
          this.show.managerType = data.userManager.typeName
          this.dataForm.managerType = data.userManager.managerTypes ? data.userManager.managerTypes.split(',').map((n) => Number(n)) : ''
          if (data.userManager.managerTypes) {
            this.managerTypeList.map(item => {
              this.dataForm.managerType.map(val => {
                if (item.value == val) {
                  this.managerTypeCheckValue.push(item)
                }
              })
            })
          }
          this.show.headImg = data.userManager.headImg ? this.getImageStream(data.userManager.headImg) : this.getImageStream("files/wx/images/content/headImg-wggg.png",process.env.VUE_APP_BASE_BACK_URL)
          this.dataForm.headImg = data.userManager.headImg
          if(data.userManager.miniGridInfoEntities && data.userManager.miniGridInfoEntities.length) {
            this.show.grid = data.userManager.miniGridInfoEntities.map(item => item.name).toString()
            this.dataForm.miniGrid = data.userManager.miniGridList
          }else if(data.userManager.gridInfoEntities && data.userManager.gridInfoEntities.length) {
            this.show.grid = data.userManager.gridInfoEntities.map(item => item.name).toString()
            this.dataForm.grid = data.userManager.gridList
          }
        }
      })
    },
    headImgButton(e) {
      let _this = this
      if (!this.readonly) {
        let path = "files/userHeadImg/temporary"
        this.$toast.loading({
          message: '上传中...',
          forbidClick: true,
          duration: 0,
          overlay: true
        })
        upload(e.file, path, null, function (res) {
          _this.show.headImg = getImageStream(res.fileMessage.relativePath)
          _this.dataForm.headImg = res.fileMessage.relativePath
          _this.$toast.clear()
        })
      }
      // this.dataForm.headImg = _this.dataForm.headImg
    },
    postConfirm(val) {
      if(val) {
        this.show.post = val.map(item => {
          return item.label
        }).join(',')
        this.dataForm.post = val.map(item => {
          return item.value
        })
      }
    },
    businessConfirm(val) {
      if(val) {
        this.show.businesss = val.map(item => {
          return item.label
        }).join(',')
        this.dataForm.businesss = val.map(item => {
          return item.value
        })
      }
    },
    managerTypeConfirm(val) {
      if(val) {
        this.show.managerType = val.map(item => {
          return item.label
        }).join(',')
        this.dataForm.managerType = val.map(item => {
          return item.value
        })
      }
    },
    gridChange(items) {
      if(items) {
        this.dataForm.grid = []
        this.dataForm.miniGrid = []
        let labelList = []
        items.forEach(item => {
          labelList.push(item.label)
          this.show.grid = labelList.toString()
          if(!item.parameter) {
            this.dataForm.grid.push(
              item.value
            )
          }else {
            this.dataForm.miniGrid.push(
              item.value
            )
          }
        })
      }
      this.control.managementGrid = false
    },
    dataFormOnSubmit() {
      if(this.dataForm.mobile != this.dataForm.workMobile) {
        this.$dialog.confirm({
          message: '是否将当前用户的账号修改为工作手机号',
        }).then(() => {
          this.confirm(1)
        }).catch(() => {
          this.confirm(0)
        })
      }else {
        this.confirm(null)
      }
    },
    confirm(changeUserName) {
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.control.buttonLoading = true
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/info/update'),
        method: 'POST',
        data: this.$http.adornData({
          id: parseInt(this.$globalData.userInfo.userId),
          name: this.dataForm.name,
          mobile: this.dataForm.mobile,
          manageMobile: this.dataForm.workMobile,
          headImg: this.dataForm.headImg,
          post: this.dataForm.post.length > 0 ? this.dataForm.post.toString() : '',
          charges: this.dataForm.businesss.length > 0 ? this.dataForm.businesss.toString() : '',
          managerTypes: this.dataForm.managerType.length > 0 ? this.dataForm.managerType.toString() : '',
          gridList: this.dataForm.grid,  //网格对象
          miniGridList: this.dataForm.miniGrid, // 微网格对象
          changeUserName
        })
      }).then(({data}) => {
        this.$toast.clear()
        if(data && data.code === 0) {
          this.$toast.success({
            duration: 1500,
            forbidClick: true,
            message: '修改成功',
            onClose: () => {
              this.control.buttonLoading = false
              this.readonly = true
              this.getUserInfo()
            }
          })
        }else {
          this.control.buttonLoading = false
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>

<style scoped>
/* 个人照片处的样式 start */
.info >>> .van-cell__title {
  display: flex;
  align-items: center;
}
.info >>> .van-cell__value {
  text-align: right !important;
}
.van-uploader {
  width: 100%;
}
.van-uploader >>> .van-uploader__input-wrapper {
  width: 100%;
}
.van-uploader >>> .van-uploader__wrapper--disabled {
  opacity: 1;
}
.oldSize >>> .van-cell__title > span {
  font-size: 40px !important;
}
.oldSize >>> .van-cell__value {
  font-size: 40px !important;
}
.oldSize >>> .van-field__control {
  font-size: 40px !important;
}
/* 个人照片处的样式 end */
</style>
<style lang="scss" scoped>
.personal-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.info {
  .title {
    width: 100%;
    height: 96px;
    background: #FFFFFF;
    font-size: 32px;
    // font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 96px;
    padding: 0 30px;
  }
  .personal-info {
    margin-bottom: 16px;
    .headImg {
      width: 120px;
      height: 120px;
    }
  }
  .work-info {

  }
}

.buttons {
  padding: 0 30px;
  margin-bottom: 40px;
  .button {
    width: 690px;
    height: 88px;
    background: #4581F8;
    border-radius: 8px;
    font-size: 32px;
    // font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;

  }
}
</style>
